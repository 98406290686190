import React from 'react'
import ContentBlock from 'components/contentBlock'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const Story = ({ data }) => {
  const page = data.thisPage
  return (
    <Layout>
      <div id="content">
        {page.contentBlocks.map((block, i) => (
          <ContentBlock
            key={i}
            order={i}
            block={block}
            page={page}
            data={data}
          />
        ))}
      </div>
    </Layout>
  )
}
export default Story

export const storyPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "story" }) {
      title
      slug
      contentBlocks {
        id
        slug
        template
        label
        title
        subtitle
        textPosition
        content {
          id
          childMarkdownRemark {
            html
          }
        }
        imagesFull {
          ...GatsbyImage
        }
        imagesFullMobile {
          ...GatsbyImage
        }
        imagesFullLink
        buttonLink
        imageSingle {
          ...GatsbyImage
        }
        video {
          wistiaCode
          optimizedFiles {
            file {
              url
              fileName
            }
          }
        }
        slides {
          images {
            ...GatsbyImage
          }
          caption
        }
      }
    }
  }
`
